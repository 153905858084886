<template>
    <div>
        <title-bar :title="$t('navigations.items')" />
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="tableActions"
                :addBtnText="$t('messages.addNew')"
                :addBtnLink="$helper.getAddUrl('others')"
                :option="filterOption"
                :options="filterOptions"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:itemCategory="data">
                    {{
                        $helper.getEnumTranslation("ITEM_CATEGORY", data.value.itemCategory, $i18n.locale)
                    }}
                </template>
            
                <template v-slot:insertedBy="data">
                    <div v-if="data.value.insertedBy">
                      {{ data.value.insertedBy.givenName + " " + data.value.insertedBy.familyName }}
                    </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn v-if="canUpdate" :path="$helper.getEditUrl('others', data.value.id)" />
                        <delete-btn v-if="canDelete" @pressDelete="deleteItem(data.value['@id'])" />
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>

    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "Items",
    components: {
        TableLite,
        TablePagination,
        DeleteBtn,
        TableActions,
        EditBtn,
        TitleBar,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    created() {
      this.setPermissions()
        this.load()
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                itemCategory: 'OTHER'
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.$Items.getCollection({params}, 'item_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setPermissions() {
          if (this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('CREATE', 'OTHERS')) {
            this.tableActions.push('addBtn')
          }
          this.canUpdate = this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('UPDATE', 'OTHERS')
          this.canDelete = this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('DELETE', 'OTHERS')
        },
        deleteItem(url) {
            this.deleteByUrl(this.$Skills, url, this.$t('item.deleted'), null, this.load, this.error);
        },
        onChangePage(page) {
          this.currentPage = page;
          this.load();
        },
        perPageChange(num) {
          this.currentPage = 1;
          this.perPage = num;
          this.load();
        },
        inputChange(value) {
          this.currentPage = 1;
          this.search = value;
          this.load();
        },
        setSort(offset, limit, order, sort) {
          this.sortable.order = order;
          this.sortable.sort = sort;

          this.load();
        },
    },
    data() {
        return {
            search: "",
            isLoading: false,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "id",
                sort: "asc",
            },
           canUpdate: false,
           canDelete: false,
           tableActions: ['search', 'perPage','advancedFilter'],
           filterFields: [
                {
                    label: 'forms.insertedBy',
                    name: "insertedBy",
                    value: null,
                    type: "employee",
                },
            ],
            advancedParams: [],
            columns: [
                {
                  label: this.$t("forms.name"),
                  field: "name",
                  sortable: true,
                },
                {
                    label: this.$t("forms.number"),
                    field: "number",
                    sortable: true,
                },
                {
                    label: this.$t("forms.crossReference"),
                    field: "crossReference",
                    sortable: true,
                },
                {
                    label: this.$t("forms.itemCategory"),
                    field: "itemCategory",
                    sortable: true,
                },
                {
                    label: this.$t("forms.insertedBy"),
                    field: "insertedBy",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
            rows: null
        };
    },
};
</script>

